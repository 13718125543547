import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/react-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import {useEffect} from "react";

export default function MarkdownEditor({editorRef, content, initialEditType= "markdown", height = "600px", uploadFiles, setUploadFiles, useFiles = true}) {
    useEffect(() => {
        if(!useFiles) editorRef.current.getInstance().removeHook('addImageBlobHook')
    }, [editorRef, useFiles])

    return (
        <>
            {editorRef && (
                <Editor ref={editorRef}
                        initialValue={content || ''}
                        initialEditType={initialEditType}
                        previewStyle={window.innerWidth > 1000 ? 'vertical': 'tab'}
                        height={height}
                        plugins={[codeSyntaxHighlight]}
                        usageStatistics={false}
                        hooks={{
                            addImageBlobHook: async(blob, callback) => {
                                if(!useFiles) return

                                let file = blob

                                if(blob instanceof Blob) {
                                    file = new File([blob], blob.name, {type: blob.type})
                                    file.preview = URL.createObjectURL(blob)
                                    file.path = blob.name
                                }

                                const newList = uploadFiles.concat(file)
                                setUploadFiles(newList)
                                callback(file.preview, blob.name)
                            }
                        }}
                />
            )}
        </>
    )
}