import {useParams} from "react-router";
import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import useForum from "../hooks/useForum";
import {useEffect, useRef, useState} from "react";
import MarkdownEditor from "../components/editor/MarkdownEditor";
import FileDropzone from "../components/editor/FileDropzone";
import UploadedFiles from "../components/editor/UploadedFiles";
import {deleteTaskAttachedFile} from "../store/TasksInfo";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";

export default function ForumNewPost() {
    const {forumId} = useParams()
    const {useCategories, categories} = useForum(forumId)
    const [category, setCategory] = useState('')
    const [subject, setSubject] = useState("")
    const [uploadFiles, setUploadFiles] = useState([])
    const [files, setFiles] = useState([])

    const editorRef = useRef(null)
    const dispatch = useDispatch()

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    useEffect(() => {
        if(!useCategories) return
        if((!category || category === '') && categories.length > 0) {
            let categoryId = categories.findIndex(cat => cat.defaultSelected === true)
            if(categoryId < 0) categoryId = 0
            setCategory(categories[categoryId].id)
        }
    }, [categories, category, useCategories])

    const handleChangeCategory = event => setCategory(event.target.value)
    const handleChangeSubject = event => setSubject(event.target.value)

    const onSubmit = (e) => {
        e.preventDefault()
        reset()
    }

    const reset = () => {
        setSubject("")
        editorRef.current.getInstance().setMarkdown("", true)
        if(useCategories && categories.length > 0) {
            let categoryId = categories.findIndex(cat => cat.defaultSelected === true)
            if(categoryId < 0) categoryId = 0
            setCategory(categories[categoryId].id)
            setUploadFiles([])
            setFiles([])
        }
    }

    const handleUploadedFileDeleted = (file) => {
        // TODO: 이거 호출되면 안됨
        dispatch(deleteTaskAttachedFile({attachmentId:file.id, taskId: 0})).then(() => {
            toast.success("삭제하였습니다.")
        })
    }

    return (
        <>
            <h1>새로 작성하기</h1>
            <Box component="form" onSubmit={handleSubmit} sx={{my:3}}>
                <Grid container spacing={2} flex flexDirection="row">
                    {useCategories && (
                        <Grid item xs={2} style={{minWidth:'120px'}}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="category-label">카테고리</InputLabel>
                                <Select required
                                        id="category"
                                        label="카테고리"
                                        value={category}
                                        labelId="category-label"
                                        onChange={handleChangeCategory}
                                        displayEmpty>
                                    {categories.map((cat) => (
                                        <MenuItem key={cat.id} value={cat.id}>{cat.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item flexGrow="3">
                        <TextField name="subject"
                                   required
                                   fullWidth
                                   xs="auto"
                                   id="subject"
                                   label="제목"
                                   onChange={handleChangeSubject}
                                   value={subject}
                                   autoFocus={true} />
                    </Grid>
                    <Grid item xs={12} minWidth={400}>
                        <MarkdownEditor editorRef={editorRef} initialEditType="wysiwyg" uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} />
                    </Grid>
                    {(files && files.length > 0) && (
                        <Grid item xs={12}>
                            <UploadedFiles files={files} onDelete={handleUploadedFileDeleted}/>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FileDropzone onUpdated={(files) => setUploadFiles(files)} uploadFiles={uploadFiles} setUploadFiles={setUploadFiles}/>
                    </Grid>
                    <Grid item xs={12} flex justifyContent="center">
                        <Button variant="contained" size="large" type="submit" onClick={onSubmit}>전송</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
